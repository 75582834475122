.container {
  width: calc(100% - 30px);
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.container.fluid {
  max-width: unset;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.col,
*[class^='col-'],
*[class*=' col-'] {
  padding: 0 15px;
  width: 100%;
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}

.col-1 {
  max-width: calc(8.3333334% - 30px);
  flex: 0 0 calc(8.3333334% - 30px);
}

.col-2 {
  max-width: calc(16.666667% - 30px);
  flex: 0 0 calc(16.666667% - 30px);
}

.col-3 {
  max-width: calc(25% - 30px);
  flex: 0 0 calc(25% - 30px);
}

.col-4 {
  max-width: calc(33.3333334% - 30px);
  flex: 0 0 calc(33.3333334% - 30px);
}

.col-5 {
  max-width: calc(41.6666667% - 30px);
  flex: 0 0 calc(41.6666667% - 30px);
}

.col-6 {
  max-width: calc(50% - 30px);
  flex: 0 0 calc(50% - 30px);
}

.col-7 {
  max-width: calc(58.3333334% - 30px);
  flex: 0 0 calc(58.3333334% - 30px);
}

.col-8 {
  max-width: calc(66.6666667% - 30px);
  flex: 0 0 calc(66.6666667% - 30px);
}

.col-9 {
  max-width: calc(75% - 30px);
  flex: 0 0 calc(75% - 30px);
}

.col-10 {
  max-width: calc(83.3333334% - 30px);
  flex: 0 0 calc(83.3333334% - 30px);
}

.col-11 {
  max-width: calc(91.6666667% - 30px);
  flex: 0 0 calc(91.6666667% - 30px);
}

.col-12 {
  max-width: calc(100% - 30px);
  flex: 0 0 calc(100% - 30px);
}

.section-title-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.section-title {
  margin: 0 auto 40px auto;
  font-family: Josefin Sans, sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  border-bottom: none;
}

.section-title.bordered {
  padding: 12px 20px;
  border: 1px solid #000;
  border-bottom-width: 4px;
}

.section-title:not(.bordered)::before,
.section-title:not(.bordered)::after {
  content: '';
  width: 40px;
  height: 1px;
  background-color: #000;
  display: inline-block;
  position: relative;
  top: -8px;
}

.section-title:not(.bordered)::before {
  margin-right: 16px;
}

.section-title:not(.bordered)::after {
  margin-left: 16px;
}

.p-0 {
  padding: 0 !important;
}

@media (max-width: 900px) {
  .container {
    width: unset;
    max-width: 500px;
    padding: 0 12px;
  }

  .row {
    margin-left: -12px;
    margin-right: -12px;
  }

  .col,
  *[class^='col-'],
  *[class*=' col-'] {
    padding: 0 12px;
  }

  .col-1 {
    max-width: calc(8.3333334% - 24px);
    flex: 0 0 calc(8.3333334% - 24px);
  }

  .col-2 {
    max-width: calc(16.666667% - 24px);
    flex: 0 0 calc(16.666667% - 24px);
  }

  .col-3 {
    max-width: calc(25% - 24px);
    flex: 0 0 calc(25% - 24px);
  }

  .col-4 {
    max-width: calc(33.3333334% - 24px);
    flex: 0 0 calc(33.3333334% - 24px);
  }

  .col-5 {
    max-width: calc(41.6666667% - 24px);
    flex: 0 0 calc(41.6666667% - 24px);
  }

  .col-6 {
    max-width: calc(50% - 24px);
    flex: 0 0 calc(50% - 24px);
  }

  .col-7 {
    max-width: calc(58.3333334% - 24px);
    flex: 0 0 calc(58.3333334% - 24px);
  }

  .col-8 {
    max-width: calc(66.6666667% - 24px);
    flex: 0 0 calc(66.6666667% - 24px);
  }

  .col-9 {
    max-width: calc(75% - 24px);
    flex: 0 0 calc(75% - 24px);
  }

  .col-10 {
    max-width: calc(83.3333334% - 24px);
    flex: 0 0 calc(83.3333334% - 24px);
  }

  .col-11 {
    max-width: calc(91.6666667% - 24px);
    flex: 0 0 calc(91.6666667% - 24px);
  }

  .col-12 {
    max-width: calc(100% - 24px);
    flex: 0 0 calc(100% - 24px);
  }

  .section-title {
    font-size: 16px;
    line-height: 20px;
  }

  .section-title.bordered {
    padding: 5px 10px;
  }
}
